.ag-input-field-input.ag-text-field-input, .ag-wrapper.ag-input-wrapper.ag-text-field-input-wrapper, .ag-labeled.ag-label-align-left.ag-text-field.ag-input-field{
    color: #000 !important;
  }
  .ag-floating-bottom-container {
    background-color: #fff;
    opacity: 9;
    color:red !important;
    font-weight:bold;
    font-size: 20px;
  }

  .ag-floating-bottom-container .ag-row .ag-cell {
    color:  #2370e4
  }
 
  .ag-theme-alpine .ag-row:nth-child(even) {  
    background-color:#f7f7f9;; 	
  }  


  .ag-header-cell{
    /* Necessary to allow for text to grow vertically */
    background-color: #283db1;
    border-right: #ccc 1px solid;
    text-align: left;    
  }
  

  .btn-info {
    background-color: #283db1;
  }
  
  .ag-floating-filter-input{
    color: #000 !important;
  }
.classtopcustomer{
  margin-top: -5%;
  font-weight:bold;
  color: white;
}
  .inventoryCell{
    text-align: left;
    border: 1px solid #dde2eb;
  }

  .inventoryContainer{
    background-color: #283db1;
  }
  .ag-header-cell .ag-header-cell-resize {
    display: block !important;
  }
  .ag-header-cell .ag-header-cell-resize::after {
    content: "";
    position: absolute;
    z-index: 1;
    display: block;
    left: calc(50% - 1px);
    width: 2px;
    height: 30%;
    top: calc(50% - 15%);
    background-color: rgba(186, 191, 199, 0.5);
    background-color: var(--ag-header-column-resize-handle-color, rgba(186, 191, 199, 0.5));
  }

  .ag-header-cell .ag-header-cell-text {
    color: white;
    text-align: 'left'; 
    font-size: 11px;
  }

  .ag-header-container {
    background-color:#283db1;
  }

  .ag-row-event {
    background:#fff8
  }

  .ag-row-odd {
    background: #e3e9eb;
  }

  .ag-header-viewport{
    background-color: #283db1;
  }
 