.footerDiv {
    height: 10%;
    background-color: #283db1;
    width: 100%;
}

.footerText{
    padding: 2%;
    color: white;
}
.m-0 {
    margin: 0;
    
}