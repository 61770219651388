.App {
  text-align: center;
  height: 100%;
}
body {
  overflow-x: hidden;
}
#root{
  height: 100%;
}

.leftmargin {
  margin-left: -25%;
}

.Navigation {
  margin-left: -7%;
}

.navbar-nav {
  display: none;
}

.otherLogo {
  margin-top: -3%;
  margin-right: 5%;
  margin-left: 5%;
  width: 38%;
}

.displayButton {
  display: none;
}

.card-body{
  padding: 0 1rem !important;
}

.loginPIC {
  margin-top: 5%;
  width: 90%;
  margin-left: 33%;
}

.linkStyle {
  text-align: center;
  color: red;
}

.fiftypercent {
  width: 50%;
}

.ag-header {
  /* Necessary to allow for text to grow vertically */
  background-color: #0f719e;
  color: #fff;
}

.ag-header-cell {
  /* Necessary to allow for text to grow vertically */
  background-color: #0e71eb;
  color: #fff;
}
.buttonstop {
  text-align: left;

  margin-top: -5%;
}

.imgSize {
  width: 100px;
  height: 75px;
  display: block;
  margin-left: 100%;
}

.ag-body-viewport {
  overflow-x: hidden !important;
}

.mainHeading {
  margin-top: 1%;
  margin-bottom: 2%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
html,
body {
  height: 100%;
  width: 100%;
  place-items: center;
}

.loginpic {
  margin-top: 5%;
  background-repeat: no-repeat;
}
.menu {
  margin-left: 50%;
}

.tableDiv {
  height: 100%;
  overflow-y: hidden;
  white-space: nowrap;
}
table {
  border: 1px solid #666;
  width: 98%;

  border-collapse: collapse;
  overflow-y: hidden;
  white-space: nowrap;
  box-shadow: 10px 10px 5px #ccc;
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  -khtml-box-shadow: 10px 10px 5px #ccc;
}
th,
td {
  padding: 10px 15px;
  border: 1px #ccc solid;
}
th {
  font-weight: 400;
  /* background: -webkit-linear-gradient(top, #0f719e, #4ca1af); */
  text-align: center;
  color: #fff;
}

tr:nth-child(even) {
  background-color: #d6e7ea;
}

.tdrow1 {
  background-color: #4ca1af;
}

.tdrow2 {
  background-color: #0f719e;
}

.orangered {
  color: orangered;
}

.red {
  background-color: red;
}

.blue {
  background-color: rgb(42, 42, 218);
}

.nav-link {
  color: #fff !important;
}

.nav-link:hover {
  background-color: #000 !important;
}

::selection {
  background: #4ca1af;
  color: #fff;
}
.wrapper {
  overflow: hidden;

  background: #e9ecef;
  padding: 30px;
  margin-top: 1%;
  border-radius: 5px;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
}
.wrapper .title-text {
  display: flex;
  width: 200%;
}
.wrapper .title {
  width: 50%;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .slide-controls {
  position: relative;
  display: flex;
  height: 50px;
  width: 100%;
  overflow: hidden;
  margin: 30px 0 10px 0;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 5px;
}

.align-center {
  text-align: center;
  color: red;
  margin-left: 10%;
}

.footer {
  color: #fff;
}

.slide-controls .slide {
  height: 100%;
  width: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s ease;
}
.slide-controls label.signup {
  color: #000;
}
.slide-controls .slider-tab {
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  z-index: 0;
  border-radius: 5px;
  background-color: #283db1;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
input[type="radio"] {
  display: none;
}
#signup:checked ~ .slider-tab {
  left: 50%;
}
#signup:checked ~ label.signup {
  color: #fff;
  cursor: default;
  user-select: none;
}
#signup:checked ~ label.login {
  color: #000;
}
#login:checked ~ label.signup {
  color: #000;
}
#login:checked ~ label.login {
  cursor: default;
  user-select: none;
}
.wrapper .form-container {
  width: 100%;
  overflow: hidden;
}
.form-container .form-inner {
  display: flex;
  width: 200%;
}
.form-container .form-inner form {
  width: 50%;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.form-inner .signupForm .field {
  height: 50px;
  width: 100%;
  margin-top: 2%;
}
.form-inner .signupForm .field input {
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 15px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  border-bottom-width: 2px;
  font-size: 17px;
  transition: all 0.3s ease;
}
.form-inner .signupForm .field input:focus {
  border-color: #fc83bb;
  /* box-shadow: inset 0 0 3px #fb6aae; */
}
.form-inner .signupForm .field input::placeholder {
  color: #999;
  transition: all 0.3s ease;
}
.signupForm .field input:focus::placeholder {
  color: #b3b3b3;
}
.form-inner .signupForm .pass-link {
  margin-top: 5px;
}
.form-inner .signupForm .signup-link {
  text-align: center;
  margin-top: 30px;
}
.form-inner .signupForm .pass-link a,
.form-inner .signupForm .signup-link a {
  color: #4ca1af;
  text-decoration: none;
}
.form-inner .signupForm .pass-link a:hover,
.form-inner .signupForm .signup-link a:hover {
  text-decoration: underline;
}
.signupForm .btn {
  height: 50px;
  width: 100%;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
.signupForm .btn .btn-layer {
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background-color: #283db1;
  border-radius: 5px;
  transition: all 0.4s ease;
}
.signupForm .btn:hover .btn-layer {
  left: 0;
}
.signupForm .btn input[type="submit"] {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  background: none;
  border: none;
  color: #fff;
  padding-left: 0;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .imgSize {
    margin-left: 1%;
    width: 36%;
  }

  .menu {
    margin-left: 0;
  }

  .loginpic {
    display: none;
  }

  .leftmargin {
    margin-left: -50%;
  }
}

.card-body{
  padding: 1rem;
}

input#ag-294-input, input#ag-285-input{
  color: #000;
}

.signupForm{
  width: 50%;
}

.welcomeTitle{
  margin-top: 8%;
}

.App {
  position: relative;
}

